import styles from './accordion.module.scss'
import React, { useMemo, useState } from 'react'
import { groupNeumaticos } from '../utils'
import { AccordionMotoContent } from './AccordionMotoContent'
import AccordionHeader from './AccordionHeader'
import Accordion from './Accordion'
import ComprarNeumaticoCTA from '../Ctas/ComprarNeumaticoCTA'
import StandardModal from '../../modal/standardModal/StandardModal'
import { t } from '../../../i18n'
import RequestTyreMoto, {
  RequestTyreMotoData,
} from '../../RequestTyre/RequestTyreMoto'
import RequestMotoTyreCTA from '../Ctas/RequestMotoTyreCTA'
import {
  ModeloMotoInfo,
  NeumaticoMotoInfo,
  NeumaticoMotoProducto,
  NeumaticosMoto,
} from '../types'

const headerItems = [
  {
    title: 'landing_neumaticos-moto.list_header.item_one',
    className: styles.header_title,
  },
  {
    title: ' ',
    className: styles.header_aplicacion,
  },
  {
    title: 'landing_neumaticos-moto.list_header.item_four',
    className: styles.header_button,
  },
]
interface Props {
  modelo: ModeloMotoInfo
  neumaticos: NeumaticosMoto
}
export default function NeumaticosMotoAccordion({ modelo, neumaticos }: Props) {
  const [showModal, setShowModal] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreMotoData | null
  )
  const [successMessage, setSuccessMessage] = useState(false)

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const grouped = useMemo(
    () => groupNeumaticos(neumaticos, 'llanta'),
    [neumaticos]
  )

  const doContent = (neumatico: NeumaticoMotoInfo & NeumaticoMotoProducto) => {
    return (
      <AccordionMotoContent
        key={`accordion_moto_${neumatico.producto.id_navision}`}
        neumatico={neumatico}
        modelo={modelo}
        comprarCta={
          <ComprarNeumaticoCTA
            neumaticoInfo={neumatico}
            marcaInfo={modelo.marca}
            buttonClass={'rodi_button'}
          />
        }
        requestTyreCta={
          <RequestMotoTyreCTA
            neumatico={neumatico}
            modelo={modelo}
            openModal={setShowModal}
            selectNeumatico={setSelectedNeumatico}
          />
        }
      />
    )
  }
  return (
    <React.Fragment>
      <Accordion
        grouped={grouped}
        header={<AccordionHeader items={headerItems} />}
        contentRenderFnc={doContent}
        itemHeader={'landing_neumaticos.list_item_header.llanta'}
      />
      <StandardModal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyreMoto
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
        />
      </StandardModal>
    </React.Fragment>
  )
}
