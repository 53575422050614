import React from 'react'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../components/Layout'
import { t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import styles from './fichas.module.scss'
import {
  getModeloImagenes,
  getModeloMarca,
  getMotoLabels,
  selectLowestPrice,
} from '../../components/FichasNeumaticos/utils'
import NeumaticoMotoCard from '../../components/FichasNeumaticos/Cards/NeumaticoMotoCard'
import SeleccionarMedidasCTA from '../../components/FichasNeumaticos/Ctas/SeleccionarMedidasCTA'
import NeumaticosMotoAccordion from '../../components/FichasNeumaticos/Accordion/NeumaticosMotoAccordion'

export default function FichaModelosMotoPage({
  data: {
    modeloInfo,
    neumaticosInfo: { nodes: neumaticos },
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_es_mobile,
    banners_ca_mobile,
  },
}: PageProps<Queries.FichaModelosMotoPageQuery>) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
  }

  const { fichas } = getModeloImagenes(imagenes)

  const medidasRef = React.createRef<HTMLDivElement>()

  const labels = getMotoLabels(neumaticos)
  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos-moto.marca', { marca: marca.toLowerCase() }),
        },
        {
          display: modelo,
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: getModeloMarca(modeloInfo),
              image: fichas.length && fichas[0].url,
              description: modeloInfo[textWithLocale('recomendado_para')],
              brand: marca,
              price: selectLowestPrice(
                neumaticos.map((neum) => neum.producto)
              ).toString(),
            })
          ),
        }}
      />
      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <NeumaticoMotoCard
            modelo={modeloInfo}
            banners={banners}
            cta={<SeleccionarMedidasCTA reference={medidasRef} />}
            labels={labels}
          />
        </section>
        <section
          className={styles.ficha_neumatico__accordion_content}
          ref={medidasRef}>
          <h2 className={styles.total_count}>
            {neumaticos.length}{' '}
            {t('landing_neumaticos.info.medidas_encontradas')} {marca} {modelo}
          </h2>
          <NeumaticosMotoAccordion
            neumaticos={neumaticos}
            modelo={modeloInfo}
          />
        </section>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FichaModelosMotoPage(
    $idModelo: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_desktop: String
    $banners_pt_mobile: String
  ) {
    modeloInfo: modelosMoto(id_site_modelo_neumatico_moto: { eq: $idModelo }) {
      ...modeloMotoInfo
      ...modeloMotoMarca
      ...modeloMotoImagen
    }
    neumaticosInfo: allNeumaticosMoto(
      filter: { id_site_modelo_neumatico_moto: { eq: $idModelo } }
    ) {
      nodes {
        ...neumaticoMotoInfo
        ...productoMotoInfo
      }
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
